import * as React from 'react'
import Layout from '../components/layout'
import {Container} from "react-bootstrap"

const About = () => {
  return (
    <Layout pageTitle="About/Contact">
      <div className='home'>
        <Container>
          <h1>About</h1>
          <h2>Studio Coat exists to be a creative company that makes digital solutions to online problems. From building websites to interactive experiences and using A.R. to A.I., with Studio Coat, the goal is to use technology to improve the online experiences and making new technology tangible and understandable.</h2>
          <hr/>
          <h1>Contact</h1>
          <h2 className='contactus'>Please feel free to contact us. <br/>mail to <a href='mailto:contact@studiocoat.nl'>contact@studiocoat.nl</a><br/> or call +31 6 83 56 61 14</h2>
        </Container>
      </div>
    </Layout>
  )
}
export default About